import React from 'react';
import Logo from 'src/assets/icons/logo.svg';
import Plus from 'src/assets/icons/plus-circle.svg';
import { Footnote } from 'src/utils/footnote';

const Table = ({ data, id }) => {
  let tableHeader;
  let tbodyData = JSON.parse(JSON.stringify(data)); // deep clone
  // if first row is a "column head" it will be displayed within thead (for semantic reasons)
  if (data.rows[0]?.columnsHead) {
    tableHeader = (
      <thead>
        <tr>
          {data.rows[0]?.columnsHead.map((columnsHead, index) => (
            <th
              key={index}
              className="pt-10 px-3 pb-5 border-b-2 border-gray-blue text-left"
            >
              {columnsHead}
            </th>
          ))}
        </tr>
      </thead>
    );
    tbodyData.rows = tbodyData.rows.slice(1);
  }
  return (
    <>
      <div className="flex flex-row container justify-between" id={id}>
        <div className="flex flex-row">
          {data.plus && <Plus className="w-5 mr-2" />}
          <h3
            className="text-3xl font-bold"
            dangerouslySetInnerHTML={{ __html: data.headline }}
          ></h3>
        </div>
        <div className="w-40">{data.logo && <Logo />}</div>
      </div>
      <table className="container container--table text-lg w-full">
        {tableHeader}
        <tbody>
          {tbodyData.rows.map((tableRows, index) => {
            return (
              <tr key={index}>
                {tableRows.columns &&
                  tableRows.columns.map((column, index) => {
                    return (
                      <td
                        key={index}
                        style={{ width: tbodyData.size[index] }}
                        className="pt-5 px-3 pb-5 border-b-2 border-white"
                      >
                        <Footnote>{column}</Footnote>
                      </td>
                    );
                  })}

                {tableRows.columnsHead &&
                  tableRows.columnsHead.map((columnHead, index) => (
                    <th
                      key={index}
                      style={{ width: tbodyData.size[index] }}
                      className="pt-10 px-3 pb-5 border-b-2 border-gray-blue text-left"
                      dangerouslySetInnerHTML={{ __html: columnHead }}
                    ></th>
                  ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
export default Table;
