import React from 'react';
import { graphql } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';
import DotIcon from 'src/assets/icons/dot.svg';
import MainLayout from 'src/layout/MainLayout';
import {
  SectionHead,
  Paragraphs,
  Grid,
  List,
  Button,
  HalvedContent,
  ContentBox
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';
import content from 'src/content/unsere-dienstleistungen/personaldienstleistungen';
import Table from 'src/components/Table';
import { Footnote, FootnoteOutput } from 'src/utils/footnote';

const PersonaldienstleistungenPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
  >
    <section className="bg-light-blue">
      <SectionHead headline={content.intro.headline} centered>
        <Paragraphs items={content.intro.paragraphs} innerHTML />
      </SectionHead>
      <Grid>
        {content.vorteile.map((card, index) => (
          <Card key={index}>
            <CardHeader headline={card.headline}>
              <card.icon />
            </CardHeader>
            <p>{card.paragraph}</p>
          </Card>
        ))}
      </Grid>
    </section>
    <section id="lohn-und-gehaltsabrechnungen">
      <SectionHead headline={content.abrechnungen.headline} centered />
      <div className="container container--simple">
        <Card>
          <CardHeader headline={content.abrechnungen.card.headline} parallel>
            <content.abrechnungen.card.icon />
          </CardHeader>
          <Paragraphs items={content.abrechnungen.card.paragraphs} />
        </Card>
      </div>
    </section>
    <section>
      <SectionHead headline={content.dienstleistungspakete.headline} centered />
      <Grid>
        {content.dienstleistungspakete.cards.map((card, index) => (
          <Card key={index} background={card.background}>
            <CardHeader headline={card.headline} centered>
              <card.icon />
            </CardHeader>
            <List items={card.listItems} />
            <div className="mt-auto">
              <div className="mb-8">
                <span className="font-bold">
                  <Footnote>{card.subText}</Footnote>
                </span>
                <br></br>
              </div>
              <Button href={card.buttonLink} variant="ghost" fullWidth>
                {card.buttonText}
              </Button>
            </div>
          </Card>
        ))}
      </Grid>
    </section>

    <section className="no-children-margin">
      <HalvedContent id="dienstleistungDetailBasis">
        <ContentBox
          background={content.dienstleistungDetailBasis.background}
          align="right"
        >
          <div className="flex mb-5">
            <content.dienstleistungDetailBasis.icon className="w-18 h-18 fill-current" />
            <h3 className="self-center ml-3">
              {content.dienstleistungDetailBasis.headline}
            </h3>
          </div>
          <p
            className="font-bold text-3xl"
            dangerouslySetInnerHTML={{
              __html: content.dienstleistungDetailBasis.areas[0].subline
            }}
          ></p>
          <List items={content.dienstleistungDetailBasis.areas[0].listItems} />
          <p
            className="font-bold text-base pt-10 pb-3"
            dangerouslySetInnerHTML={{
              __html: content.dienstleistungDetailBasis.areas[1].subline
            }}
          ></p>
          <style type="text/css">
            {
              '#dienstleistungDetailBasis ul ~ ul li:nth-child(odd){width: 35% !important;}#dienstleistungDetailBasis ul ~ ul li:nth-child(even){width: 65% !important;}'
            }
          </style>
          <List
            items={content.dienstleistungDetailBasis.areas[1].listItems}
            icon={DotIcon}
            columns={2}
          />
          <div className="pt-10">
            <Button
              href={'#priceTable'}
              variant={content.dienstleistungDetailBasis.buttonVariant}
            >
              {content.dienstleistungDetailBasis.buttonText}
            </Button>
          </div>
        </ContentBox>
        <BackgroundImage fluid={data.manWorking.childImageSharp.fluid} />
      </HalvedContent>
    </section>

    <section className="no-children-margin">
      <HalvedContent id="dienstleistungDetailComfort">
        <BackgroundImage
          fluid={data.youngBusinessman.childImageSharp.fluid}
          className="pt-20"
        />
        <ContentBox
          background={content.dienstleistungDetailComfort.background}
          align="left"
        >
          <div className="flex mb-5">
            <content.dienstleistungDetailComfort.icon className="w-18 h-18 fill-current" />
            <h3 className="self-center ml-3">
              {content.dienstleistungDetailComfort.headline}
            </h3>
          </div>
          <p
            className="font-bold text-3xl"
            dangerouslySetInnerHTML={{
              __html: content.dienstleistungDetailComfort.areas[0].subline
            }}
          ></p>
          <List
            items={content.dienstleistungDetailComfort.areas[0].listItems}
          />
          <p
            className="font-bold text-base pt-10 pb-3"
            dangerouslySetInnerHTML={{
              __html: content.dienstleistungDetailComfort.areas[1].subline
            }}
          ></p>
          <List
            items={content.dienstleistungDetailComfort.areas[1].listItems}
            icon={DotIcon}
            columns={2}
          />
          <div className="pt-10">
            <Button
              href={'#priceTable'}
              variant={content.dienstleistungDetailComfort.buttonVariant}
            >
              {content.dienstleistungDetailComfort.buttonText}
            </Button>
          </div>
        </ContentBox>
      </HalvedContent>
    </section>

    <section className="no-children-margin">
      <HalvedContent id="dienstleistungDetailPremium">
        <ContentBox
          background={content.dienstleistungDetailPremium.background}
          align="right"
        >
          <div className="flex mb-5">
            <content.dienstleistungDetailPremium.icon className="w-18 h-18 fill-current" />
            <h3 className="self-center ml-3">
              {content.dienstleistungDetailPremium.headline}
            </h3>
          </div>
          <p
            className="font-bold text-3xl"
            dangerouslySetInnerHTML={{
              __html: content.dienstleistungDetailPremium.areas[0].subline
            }}
          ></p>
          <List
            items={content.dienstleistungDetailPremium.areas[0].listItems}
          />
          <p
            className="font-bold text-base pt-10 pb-3"
            dangerouslySetInnerHTML={{
              __html: content.dienstleistungDetailPremium.areas[1].subline
            }}
          ></p>
          <List
            items={content.dienstleistungDetailPremium.areas[1].listItems}
            icon={DotIcon}
            columns={1}
          />
          <div className="pt-10">
            <Button
              href={'#priceTable'}
              variant={content.dienstleistungDetailPremium.buttonVariant}
            >
              {content.dienstleistungDetailPremium.buttonText}
            </Button>
          </div>
        </ContentBox>
        <BackgroundImage
          fluid={data.youngBusinessWoman.childImageSharp.fluid}
        />
      </HalvedContent>
    </section>

    <section className="no-children-margin">
      <Table data={content.priceTable} id="priceTable"></Table>
    </section>

    <section className="no-children-margin">
      <Table data={content.zusatzangebote} id="zusatzangebote"></Table>
    </section>

    <section className="no-children-margin">
      <FootnoteOutput />
    </section>
  </MainLayout>
);

export default PersonaldienstleistungenPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-wearing-light-blue-shirt-laughing-wide.jpg" }
    ) {
      ...image
    }
    manLookingOutOfWindow: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-looking-out-of-window.jpg" }
    ) {
      ...image
    }
    womanOnCouchLaughing: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-on-couch-laughing.jpg" }
    ) {
      ...image
    }
    manWorking: file(
      sourceInstanceName: { eq: "images" }
      relativePath: {
        eq: "man-working-with-spectrometer-at-the-manufacturing2.jpg"
      }
    ) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    youngBusinessWoman: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "young-business-woman-at-outdoor2.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    youngBusinessman: file(
      sourceInstanceName: { eq: "images" }
      relativePath: {
        eq: "young-businessman-in-factory-holding-component2.jpg"
      }
    ) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
