import ExperienceSkillIcon from 'src/assets/icons/experience-skill.svg';
import LoveTimeIcon from 'src/assets/icons/love-time.svg';
import IdeaIcon from 'src/assets/icons/idea.svg';
import FairytaleIcon from 'src/assets/icons/fairytale.svg';
import BeachChairIcon from 'src/assets/icons/comfort-icon.svg';
import JobbagIcon from 'src/assets/icons/jobbag.svg';
import LaptopCodeIcon from 'src/assets/icons/laptop-code.svg';

const content = {
  title: 'Personaldienstleistungen',
  headvisualHeadline: 'Personaldienstleistungen',
  intro: {
    headline:
      'Personaldienstleistungen der NBB - Dynamisch. Flexibel. Professionell.',
    paragraphs: [
      'Jedes Unternehmen erstellt Lohn- und Gehaltsabrechnungen. Dazu gehört viel administrativer Aufwand und es werden Mitarbeiterkapazitäten gebunden. Machen Sie es sich einfach und wählen Sie eines unserer Dienstleistungsmodelle rund ums Personal. Dabei sparen Sie Kosten und Zeit, weil Sie wirtschaftlich und effizient Ihre ganze Personalwirtschaft mit einem einzigen Partner regeln.',
      '<strong>Ihre Vorteile auf einen Blick:</strong>'
    ]
  },
  vorteile: [
    {
      icon: ExperienceSkillIcon,
      headline: 'Kompetenz aus einer Hand',
      paragraph:
        'Wir haben langjährige Erfahrung wenn es um Lohn- und Gehaltsabrechnungen geht. Mit der webbasierten ADP Payroll-Lösung (PAISY®.) setzen wir dafür eines der führenden Personalabrechnungs- und Informationssysteme ein. Vertrauen Sie auf die korrekte und termingerechte Abrechnung.'
    },
    {
      icon: LoveTimeIcon,
      headline: 'Sparen Sie Kosten und Zeit',
      paragraph:
        'Ihre Mitarbeiter werden von aufwendigen Routineaufgaben befreit. Das senkt Personalkosten, setzt Kapazitäten frei und steigert die Effektivität bei Personalbeschaffung, -betreuung und -entwicklung.'
    },
    {
      icon: IdeaIcon,
      headline: 'Flexibilität, Sicherheit, Diskretion',
      paragraph:
        'Wir bieten Ihnen maßgeschneiderte Lösungen. Für jede Unternehmensgröße und Struktur. Und natürlich werden alle Mitarbeiter - sowie Lohn- und Gehaltsdaten streng vertraulich behandelt.'
    }
  ],
  abrechnungen: {
    headline: 'Lohn- und Gehaltsabrechnungen',
    card: {
      icon: LaptopCodeIcon,
      headline: 'Unsere Software',
      paragraphs: [
        'PAISY® erlaubt eine hohe Individualität in einem Standardprogramm. Mit der Software werden speziell für Ihr Unternehmen und dessen unterschiedliche Anforderungen Gehalts- und Lohnabrechnungen erstellt und in einer umfassenden Informationsplattform zur Verfügung gestellt.',
        'Wir organisieren dabei für Sie das Stammdatenmanagement aller Personalsätze und unterstützen Sie in der effizienten Verwaltung aller relevanten Mitarbeiterdaten. Darauf aufbauend übernehmen wir die Personalabrechnung sowie das Personalcontrolling.',
        'Aufgrund jahrelangem anwendungsbasierten Wissen, sind wir der richtige Ansprechpartner für Sie.'
      ]
    }
  },
  dienstleistungspakete: {
    headline: 'Unsere Dienstleistungspakete',
    cards: [
      {
        icon: JobbagIcon,
        background: 'light-steel-blue',
        headline: 'Basis',
        listItems: [
          'Gehaltsabrechnung für gängige Mitarbeiter-Gruppen',
          'Zugriff auf Zeiterfassung',
          'Standardangebot im PSS und Org.-Manager',
          'Zugriff auf vorhandene Präsenztrainings und Webinare'
        ],
        subText:
          'Preisvariationen#(footnote-system|2)# von 27,50 EUR pro Mitarbeiter#(footnote-gender|1)# bis 34,50 EUR pro Mitarbeiter#(footnote-gender|1)#',
        buttonLink: '#dienstleistungDetailBasis',
        buttonText: 'Mehr erfahren'
      },
      {
        icon: BeachChairIcon,
        background: 'light-pink',
        headline: 'Comfort',
        listItems: [
          'Option auf wechselnde Gehaltsbestandteile',
          'Umfangreicher Zugriff auf das Personal-ServiceSystem (PSS)',
          'Reisekostenabrechnung und betriebliche Krankenversicherung',
          'On-Top-Auswertungen'
        ],
        subText:
          'Preisvariationen#(footnote-system|2)# von 27,50 EUR pro Mitarbeiter#(footnote-gender|1)# bis 34,50 EUR pro Mitarbeiter#(footnote-gender|1)#',
        buttonLink: '#dienstleistungDetailComfort',
        buttonText: 'Mehr erfahren'
      },
      {
        icon: FairytaleIcon,
        background: 'gray-blue',
        headline: 'Premium',
        listItems: [
          'Recruiting',
          'Individuelle Berechnungen inklusive Vertragserstellung',
          'Persönliche Administration und Beratung exklusiv auf Ihr Anliegen zugeschnitten'
        ],
        subText:
          'Preisvariationen#(footnote-system|2)# von 27,50 EUR pro Mitarbeiter#(footnote-gender|1)# bis 34,50 EUR pro Mitarbeiter#(footnote-gender|1)#',
        buttonLink: '#dienstleistungDetailPremium',
        buttonText: 'Mehr erfahren'
      }
    ]
  },
  dienstleistungDetailBasis: {
    headline: 'Basis',
    background: 'light-steel-blue',
    icon: JobbagIcon,
    areas: [
      {
        subline:
          'Unser NBB:Basispaket im Detail<br># Unkompliziert.Abgesichert.Versorgt.',
        listItems: [
          'Mutterschutz und Vätermonate inkl. Beratungsgespräch',
          'Erfassung von bAV-Verträgen',
          'Auszahlung von Sonderzahlungen',
          'Abwicklung von Austritten und Aufhebungsverträgen',
          'Abgabe aller lohnrelevanten Meldungen an Gesetzgeber und SV-Träger',
          'Gehaltsrelevante und vom Gesetzgeber vorgeschriebene Auswertungen',
          'PAISY®: Gehälter-Überweisung analog NÜRNBERGER Abrechnungs-Plan',
          'Standardzugriff auf PersonalServiceSystem',
          'Aufnahme Org.-Manager',
          'Verwaltung der Provisionsausfallentschädigung',
          'Beratung zum Jobticket',
          'Zugriff auf interne und bereits vorhandene Qualifizierungsangebote in Lear:N, sowie Präsenztraining und Webinare aus dem Bildungskatalog (zzgl. Buchungskosten)',
          'Einfache Zeitbildpflege in GFOS'
        ]
      },
      {
        subline: 'Zielgruppe',
        listItems: [
          '"Standard"-Mitarbeiter#(footnote-gender|1)#',
          'Leitende Angestellte#(footnote-gender|1)# (nur Abrechnung)',
          'Mütter und Väter',
          'Mitarbeiter#(footnote-gender|1)# mit Schwerbehinderung oder Einschränkung',
          'Teilzeitangestellte#(footnote-gender|1)#',
          'Azubis#(footnote-gender|1)#'
        ]
      }
    ],

    buttonLink: '#',
    buttonText: 'Zur Preisliste',
    buttonVariant: 'ghost'
  },
  dienstleistungDetailComfort: {
    headline: 'Comfort',
    background: 'light-pink',
    icon: BeachChairIcon,
    areas: [
      {
        subline:
          'Unser NBB:Komfortpaket im Detail<br># Direkt.UpToDate.RundUmAbgedeckt.',
        listItems: [
          'Komplettverwaltung VGN-Ticket',
          'Bildung von Rückstellungen',
          'Abrechnung von Firmen-Pkw',
          'Auszahlung von Betriebsrenten',
          'On-Top-Auswertungen zur Gefährdungsbeurteilung und AGV',
          'Erstellung von Anschreiben zu Gehaltserhöhungen oder Prämienzahlungen',
          'PAISY®: Option auf eine individuelle monatliche Gehaltsdateneinspielung',
          'PSS: Verwaltung von Parkplätzen (Firmensitz GD) und Fahrtkostenzuschuss (SAP Concur); Verwaltung der digitale Personalakte und Nutzung Ausbildungsmanager (alles nach GD-Standard)',
          'Reisebuchung und Reisekostenerstattung über SAP Concur Zugriff auf Software, Abrechnung erfolgt pro Meldung quartalsweise separat',
          'Komplettverwaltung der betrieblichen Krankenversicherung',
          'Erstberatung bei im Konzern relevanten Themen zu Personalpolitik und -marketing',
          'GFOS (Erläuterung zu unserem Konzept auf der nächsten Seite)',
          'Beratung zu Kindertagesstätten und –betreungsthemen'
        ]
      },
      {
        subline: 'Zusätzliche Zielgruppe',
        listItems: [
          'Werkstudenten#(footnote-gender|1)#',
          'Duale Studenten#(footnote-gender|1)#',
          'Diplomanden#(footnote-gender|1)#',
          'Mitarbeiter#(footnote-gender|1)# im Außendienst',
          'Rentner#(footnote-gender|1)# und Pensionäre#(footnote-gender|1)#',
          'Geringfügig Beschäftigte#(footnote-gender|1)#',
          'Kurzfristig Beschäftigte#(footnote-gender|1)#'
        ]
      }
    ],

    buttonLink: '#',
    buttonText: 'Zur Preisliste',
    buttonVariant: 'ghost'
  },
  dienstleistungDetailPremium: {
    headline: 'Premium',
    background: 'gray-blue',
    icon: FairytaleIcon,
    areas: [
      {
        subline:
          'Unser NBB:Premiumpaket im Detail<br>#Umfassend.Individuell.Leistungsstark.',
        listItems: [
          'Berechnung und Verarbeitung von Sonderzahlungen und Abfindungen',
          'Individuelle Gestaltung und Ausarbeitung von Sondervereinbarungen (z. B. Pflegezeit, Weiterbildungen)',
          'Abrechnung von Standesversorgung',
          'Erstellung und Umsetzung von Aufhebungsverträgen',
          'Verwaltung von MAB-Konten',
          'Berechnung von Betriebsrenten',
          'Arbeitnehmerüberlassung und Konzernleihe',
          'Übernahme des Recruiting-Prozesses für bis zu fünf garantierten Stellenbesetzungen im Kalenderjahr (ab der sechsten Stelle individuelle Buchung nötig)',
          'Erstellung von Arbeitsverträgen und -nachträgen',
          'PAISY®: Option auf separate Dateneinspielung',
          'PSS: Zugriff auf Bewerbermanagement und Zeugnisgenerator',
          'Anmeldung Berufsschule sowie Meldung und Korrespondenz mit IHK (ab der sechsten Stelle pro Lehrjahr individuelle Buchung nötig)',
          'Komplett-Verwaltung Jobrad (Betreuung Rahmenvertrag und Mitarbeiter-Beratung)'
        ]
      },
      {
        subline: 'Zusätzliche Zielgruppe',
        listItems: [
          'Mehrfachbeschäftigte#(footnote-gender|1)#',
          'Altersteilzeit',
          'Syndikus-Anstellungen'
        ]
      }
    ],

    buttonLink: '#',
    buttonText: 'Zur Preisliste',
    buttonVariant: 'ghost'
  },
  priceTable: {
    headline: 'Preisliste im Überblick.',
    logo: true,
    plus: false,
    size: ['50%', 'auto', 'auto', 'auto'],
    rows: [
      {
        columnsHead: ['', 'Basis', 'Comfort', 'Premium']
      },
      {
        columns: ['Gehaltsabrechnungspauschale', '22 EUR', '22 EUR', '22 EUR']
      },
      {
        columns: [
          'Monatspauschale Dienstleistungspaket#(footnote-price|3)# (ausgen. Praktikanten u. Rentner)',
          '3,50 EUR',
          '6,50 EUR',
          '8,50 EUR'
        ]
      },
      {
        columns: [
          'Post/Porto',
          'Wird 1:1 weiterverrechnet',
          'Wird 1:1 weiterverrechnet',
          'Wird 1:1 weiterverrechnet'
        ]
      },
      {
        columns: [
          'SAP Concur (quartalsweise Verrechnung pro Abrechnung)',
          '9 EUR bei Selbstverwaltung<br>15 EUR bei Prüfung durch GD',
          '9 EUR bei Selbstverwaltung<br>15 EUR bei Prüfung durch GD',
          '9 EUR bei Selbstverwaltung<br>15 EUR bei Prüfung durch GD'
        ]
      },
      {
        columns: [
          'Anlage neuer Mitarbeiter in allen relevanten Systemen#(footnote-price|3)#',
          '69 EUR (max. 490 EUR)',
          '69 EUR (max. 490 EUR)',
          '69 EUR (max. 490 EUR)'
        ]
      },
      {
        columnsHead: ['GFOS-Pauschale losgelöst von den Paketen', '', '', '']
      },
      {
        columns: [
          'GFOS: Funktion für Kundenbeziehung optimal eingerichtet#(footnote-price|3)#',
          '2 EUR',
          '2 EUR',
          '2 EUR'
        ]
      },
      {
        columns: [
          'GFOS: Einrichtung/Wartung Schnittstelle#(footnote-price|3)#',
          '2 EUR',
          '2 EUR',
          '2 EUR'
        ]
      },
      {
        columns: [
          'GFOS: bis Austausch über Umstellung abgeschlossen#(footnote-price|3)#',
          '5 EUR',
          '5 EUR',
          '5 EUR'
        ]
      },
      {
        columns: [
          'Abtretung Urlaubsverwaltung an Tochter (Attestbearbeitung bei NBB) #(footnote-price|3)#',
          '6,50 EUR',
          '6,50 EUR',
          '6,50 EUR'
        ]
      },
      {
        columns: [
          'Keine Optimierungen zwecks GFOS – unverhältnismäßiger Aufwand bleibt bei der NBB#(footnote-price|3)#',
          '9 EUR',
          '9 EUR',
          '9 EUR'
        ]
      }
    ],
    footNote:
      '*Preis pro Mitarbeiter#(footnote-gender|1)#, Praktikanten#(footnote-gender|1)# und Rentner#(footnote-gender|1)# kosten 25,50 ohne jeden weiteren Aufschlag; der mtl. Höchstbetrag liegt hier bei 255,00 EUR'
  },
  zusatzangebote: {
    headline: 'Zusatzangebote',
    logo: true,
    plus: true,
    size: ['80%', 'auto'],
    rows: [
      {
        columnsHead: ['Organisation', '']
      },
      {
        columns: [
          'Individuelle Anfragen zum Thema Gehalt <small>z. B. Festlegung von Gehaltsstrukturen inkl. Sonderregelung für Führungskräfte, Nettolohnoptimierung oder Einführung variabler Vergütungselemente, Bildungsurlaub , usw.</small>',
          '85 EUR/h'
        ]
      },
      {
        columns: [
          'Diverse Azubischulungen von Software-Anwendungen bis hin zur Prüfungsvorbereitung <small>(Auf Anfrage erhalten Sie gerne unser komplettes Schulungsangebot für Ihr zukünftiges Fachpersonal!)</small>',
          '100 EUR <small>pro Tag (zählt 8 Std) und pro Teilnehmer</small>'
        ]
      },
      {
        columns: [
          'Rundum-Betreuung Ihrer Azubis und Studenten inkl. Studentenprogramm <small>(Gerne betreut unser Fachkräfte-Team aus dem Bereich „Ausbildung und Studium“ Ihre Nachwuchskräfte während der kompletten Ausbildungs- und Studienzeit. Eine Übersicht über die Leistungen der „Rundum-Betreuung“ je nach Zielgruppe Azubi oder Student kann Ihnen unser Fachkräfte-Team gerne zur Verfügung stellen.)</small>',
          '600 EUR <small>für die komplette Ausbildungszeit</small>'
        ]
      },
      {
        columns: [
          'Organisation von Sonderveranstaltungen ohne regelmäßige Durchführung <small>(Natürlich informieren wir Sie zeitnah zu anstehenden Events.)</small>',
          '85 EUR/h <small >zzgl. möglicher Kosten externer Dienstleister</small>'
        ]
      },
      {
        columns: [
          'Mitarbeiterbefragungen <small>(Aktuell gilt dieses Angebot nur für die Teilnahme an Konzernumfragen.)</spsmallan>',
          'Auf Anfrage'
        ]
      },
      {
        columnsHead: ['Beratung und Coaching', '']
      },
      {
        columns: [
          'Prozessberatung beim Einkauf externer WBT inklusive Unterstützung bei der Systemintegration',
          '120 EUR/h'
        ]
      },
      {
        columns: [
          'Potenzialdiagnostik (Auswahlverfahren für Führungskräfte) <small >(Gerne unterstützen wir Sie bei der Auswahl Ihrer zukünftigen Führungskräfte!)</small>',
          ''
        ]
      },
      {
        columns: [
          '- Level 1: TN Einzelverfahren virtuell<br /> &nbsp;&nbsp;&nbsp;TN Einzelverfahren Präsenz',
          '2.200 EUR<br />3.500 EUR'
        ]
      },
      {
        columns: ['- Level 2: TN Einzelverfahren', '3.600 EUR']
      },
      {
        columns: ['- Level 3: TN Einzelverfahren', '4.200 EUR']
      },
      {
        columns: [
          'Change <small>(Gerne unterstützen wir Sie bei der Initiierung, Planung, Durchführung und Begleitung von Veränderungen!) Hinweis: Unser Angebot gilt für eine Erstberatung; die Durchführung und Begleitung übernimmt ein externen Vertragspartner.</small>',
          '120 EUR/h'
        ]
      },
      {
        columns: [
          'Betreuung und Beratung bei Firmengründungen/Betriebsübergang <small>(Wir unterstützen Sie bei einem erfolgreichen Start für Ihr neues Unternehmen!)</small>',
          '85 EUR/h <small>(für sämtliche Einrichtungskosten)</small>'
        ]
      },

      {
        columns: [
          'KVF-Ausbildung für Azubis im Außendienst <small>(Von Prüfungsvorbereitung bis zur umfassenden Betreuung sind Sie bei uns für die komplette Ausbildungszeit abgedeckt.) Hinweis: Übernahme dieser Dienstleistung für NAU und Techno durch das Team QV</small>',
          '600 EUR <small>(für komplette Ausbildung)</small>'
        ]
      },
      {
        columns: [
          'Ausbildung zum Fachmann (d/m/w) für Versicherungsvermittlung',
          '600 EUR <small> (für komplette Ausbildung; exkl. Prüfungsgebühr IHK)</small>'
        ]
      },
      {
        columns: [
          'Konkrete Umsetzung von Plänen zu Personalinstrumenten <small>(Von Homeoffice über Altersteilzeit bis hin zu Zeitwertkonten geben wir unser Bestes, Ihre Vorstellungen umzusetzen und damit die Mitarbeiterzufriedenheit in Ihrem Unternehmen zu steigern!)</small>',
          '120 EUR/h'
        ]
      },
      {
        columns: [
          'Reisezeit für Unterstützung vor Ort <small >(je nach Anfrage) (nur bei Reisen > 50 km GD Hauptverwaltung)</small>',
          '120 EUR/h'
        ]
      },
      {
        columns: [
          'audit berufundfamilie <small>(Sie wollen Ihr Unternehmen zertifizieren, dann sprechen Sie uns gerne an!)</small>',
          '85 EUR/h'
        ]
      },
      {
        columns: [
          'Flächendeckende Betreuung zu allen rechtsrelevanten Themen im Bereich Human Resources <small>(Gerne befassen sich die Experten aus unserem Team Grundsatzfragen mit Ihren individuellen Anliegen!)</small>',
          '120 EUR/h'
        ]
      },
      {
        columns: [
          'Beratung zu Sonderthemen mit den Schwerpunkten Steuer- und Arbeitsrecht',
          '120 EUR/h'
        ]
      },
      {
        columns: [
          'Profitieren Sie von unserem Angebot der Businesspartner <small>(Gerne steht Ihnen einer unserer Businesspartner bei der Herausforderung, Mitarbeiter und Unternehmen zu führen, zur Verfügung!)</small>',
          '120 EUR/h'
        ]
      },
      {
        columnsHead: ['Verwaltung', '']
      },
      {
        columns: [
          'Verwaltung von Zeitwertkonten <small>(Erhöhen Sie Ihre Attraktivität als Arbeitgeber und ermöglichen Sie Ihren Mitarbeitern Auszeiten in Form von Sabbaticals, Auszeiten in Teilzeit oder Ähnliches.)</small>',
          '85 EUR/h'
        ]
      },
      {
        columns: [
          'Ausarbeitung von Versorgungsordnungen für die betriebliche Altersvorsorge',
          '55 EUR/h'
        ]
      },
      {
        columns: ['Sonderaktionen wie Serienbriefe o. Ä.', '55 EUR/h']
      },
      {
        columns: [
          'Individuelle Anpassungen an die NÜRNBERGER Systeme (PSS, Org.-Manager, SAP Concur, 4Plan, GFOS, PAE)',
          '85 EUR/h'
        ]
      },
      {
        columns: [
          'Reisebuchungen, externe Seminaranmeldungen, Tagungs-Organisation und Planung regelmäßiger Veranstaltungen',
          '55 EUR/h'
        ]
      },
      {
        columns: [
          'Ad-hoc-Anfragen zu auf Sie speziell zugeschnittene Auswertungen<small>(wenn nicht selbst über PSS abrufbar)</small>',
          '85 EUR/h'
        ]
      },
      {
        columnsHead: ['Gesundheit', '']
      },
      {
        columns: [
          'Betriebliches Gesundheitsmanagement (Gesundheitstage über Vorträge/Seminare bis hin zur Nutzung des Bewegungs- und Entspannungsraums und digitaler Tools, z. B. Humanoo-App)',
          '85 EUR/h'
        ]
      },
      {
        columns: [
          'Betriebliches Eingliederungsmanagement (Auswertung, Versand von Briefen und Verwaltung, Gesprächsführung und Fallbetreuung)',
          '120 EUR/h'
        ]
      }
    ]
  },
  weitereServices: {
    headline: 'Weitere Services',
    paragraphs: [
      '<strong>Die NBB bietet mehr</strong>',
      'Eine gute Arbeit im Bereich Personalwesen umfasst mehr als nur die Abrechnung von Löhnen, Gehältern und Renten. Ein weiterer wichtiger Aspekt ist die Personalentwicklung. Auch hier finden Sie in uns einen verlässlichen und engagierten Partner. Gerne stellen wir Ihnen auf Sie zugeschnitten Ausbildungspläne und Fortbildungsprogramme für Ihre Belegschaft zusammen.'
    ],
    dialog: {
      buttonText: 'Mehr erfahren',
      buttonVariant: 'gray-blue',
      title: 'Weitere Services',
      paragraphs: [
        'Hierzu werden wir uns unserem breiten Fundus an Trainern, Coaches und HR-Spezialisten die für Sie passenden aussuchen und mit diesen für Sie relevante Inhalte abstimmen. Auch können wir Sie im Bereich Recruiting oder auch bei der Einführung von Reisebuchungssystemen und ähnlichen personalrelevanten IT-Systemen unterstützen.',
        'Wenn Sie hierzu mehr erfahren wollen, zögern Sie nicht, uns zu kontaktieren.'
      ]
    }
  }
};

export default content;
